<template>
    <div class="wrap-inventory">
        <router-view></router-view>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

.title-page div {
    font-size: 1.25rem;
    margin-bottom: -0.5rem;
}
</style>
